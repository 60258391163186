export default function (title, doNotConcat) {
  if (title) {
    if (doNotConcat) {
      document.title = title
    } else {
      document.title = title + ' | Digital Exhibits'
    }
  } else {
    document.title = 'Digital Exhibits'
  }
}
