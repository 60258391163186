import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'
import Logo from 'assets/images/logo.svg'

const BrandBar = ({ isFixed }) => {
  const includeMark = useMediaQuery('(min-width: 650px)')
  return (
    <div className={'brand-bar' + (isFixed ? ' fixed' : '')}>
      <a className='logo' href='/' rel='nofollow noreferrer'>
        <img src={Logo} alt='Digital Exhibits' />
      </a>
      {includeMark && (
        <a className='nd-mark' href='https://www.nd.edu' rel='nofollow noreferrer'>
          <img src='https://static.nd.edu/images/marks/gold-white/ndmark.svg' alt='University of Notre Dame' />
        </a>
      )}
    </div>
  )
}

BrandBar.propTypes = {
  isFixed: PropTypes.bool,
}

export default BrandBar
