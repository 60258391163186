import React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import HeroImage from 'assets/images/hero-image.jpg'
import PixelBar from 'assets/images/pixel-bar.svg'

const useStyles = makeStyles(() => ({
  siteHeader: {
    display: 'flex',
    justifyContent: 'center',
  },
  textSection: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 1 50%',
    alignItems: 'flex-end',
  },
  headingContainer: {
    backgroundColor: '#d8d8d8',
    paddingTop: props => props.showHeroImage ? '50px' : '20px',
    width: '100%',
  },
  heading: {
    color: '#0c2340',
    fontFamily: 'GPCMed',
    fontSize: '3rem',
    fontWeight: 'bold',
    maxWidth: '600px',
    margin: '1em 0',
    padding: '0 50px 0 70px',

    '& div': {
      fontSize: '1rem',
    },
    '& img': {
      position: 'relative',
      marginTop: '20px',
      left: '-22px',
    },
  },
  descContainer: {
    backgroundColor: '#ffffff',
    width: '100%',
  },
  desc: {
    flex: '1 0 auto',
    maxWidth: '648px',
    padding: '0 50px 0 70px',
    margin: '2em 0',
    fontSize: '1.25rem',
  },
  heroImage: {
    background: 'linear-gradient(180deg, #d8d8d8 0%, #d8d8d8 62.9999%, #ffffff 63%, #ffffff 100%)',
    display: props => props.showHeroImage ? 'flex' : 'none',
    flex: '1 1 50%',
    alignItems: 'center',
    maxWidth: '648px',
    paddingTop: '50px',

    '& img': {
      width: '100%',
    },
  },
}))

const SiteIndexHeader = () => {
  const showHeroImage = useMediaQuery('(min-width:768px)')
  const classes = useStyles({ showHeroImage })
  return (
    <div className={classes.siteHeader}>
      <div className={classes.textSection}>
        <div className={classes.headingContainer}>
          <h1 className={classes.heading}>
            Online Exhibits from Hesburgh Libraries
            <div><img src={PixelBar} alt='' /></div>
          </h1>
        </div>
        <div className={classes.descContainer}>
          <p className={classes.desc}>
            Explore curated digital content and learn about the collections
            and resources found throughout the libraries.
          </p>
        </div>
      </div>
      <div className={classes.heroImage}>
        <img src={HeroImage} alt='' />
      </div>
    </div>
  )
}

export default SiteIndexHeader
